import React, { useRef, useEffect, useState } from 'react';
import $ from 'jquery';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { changeInputFunc, resetMyAccountReducerFunc } from './reducers/myAccountSlice';
import { ToastContainer, toast } from 'react-toastify';
import { toastOptions } from './toastify';
import DisplayProfile from './components/DisplayProfile';
import EditProfile from './components/EditProfile';
import ChangePassword from './components/ChangePassword';
import Subscription from './components/Subscription';
import PaymentMethod from './components/PaymentMethod';
import BrokerSetUp from './components/BrokerSetUp';
import { handleAPIData } from './hooks/useCustomApi';
import store from './store';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';

const App = ({ message }) => {

  const dispatch = useDispatch();
  const [homeSection, setHomeSection] = useState(true);
  const [myAccountSection, setMyAccountSection] = useState(false);
  const [algoSection, setAlgoSection] = useState(false);
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const [termsCheckbox, setTermsCheckbox] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState('show active');
  const [showRegister, setShowRegister] = React.useState('');
  const [registerName, setRegisterName] = React.useState('');
  const [registerEmail, setRegisterEmail] = React.useState('');
  const [loginEmail, setLoginEmail] = React.useState('');
  const [loginPassword, setLoginPassword] = React.useState('');
  const [registerPassword, setRegisterPassword] = React.useState('');
  const [registerRepeatPassword, setRegisterRepeatPassword] = React.useState('');
  const [loading, setLoading] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState('');
  const [symbolValue, setSymbolValue] = useState('');
  const [symbolArray, setSymbolArray] = useState([]);
  const [symbolObject, setSymbolObject] = useState(null);
  const [orderType, setOrderType] = useState('regular');
  const [orderDuration, setOrderDuration] = useState('intraday');
  const [buySellToggle, setBuySellToggle] = useState(false);
  const [placeType, setPlaceType] = useState('MKT');
  const [qty, setQty] = useState('');
  const [price, setPrice] = useState('');
  const [triggerPrice, setTriggerPrice] = useState('');
  const [disclosedQty, setDisclosedQty] = useState('');
  const [dayioc, setDayioc] = useState('');
  const [splitOrder, setSplitOrder] = useState('auto');
  const [brokerType, setBrokerType] = useState('aliceBlue');
  const [apiToggleChecked, setApiToggleChecked] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [stopLossPrice, setStopLossPrice] = useState('');
  const [targetPrice, setTargetPrice] = useState('');
  const [trailingStopLossPrice, setTrailingStopLossPrice] = useState('');
  const [pipsBy, setPipsBy] = useState('');
  const [channelTrades, setChannelTrades] = useState('');
  const [isDoubleCheckbox, setIsDoubleCheckbox] = useState(false);
  const [splitQtyValue, setSplitQtyValue] = useState('');
  const [orderBookData, setOrderBookData] = useState([]);
  const [algoBookData, setAlgoBookData] = useState([]);
  const [algoBookDataLoading, setAlgoBookDataLoading] = useState(null);
  const maxQty = (type, symbol) => {
    const symbolObj = {
      'NFO:BANKNIFTY': 900,
      'NFO:NIFTY:': 1800,
      'NFO:FINNIFTY': 1800,
      'NFO:MIDCPNIFTY': 4200,
      'NFO:NIFTYNXT50': 600
    }
    const sym = `${type}${symbol}`;
    return symbolObj[sym];
  }

  let ws;

  const connectWebSocket = () => {
    // Check if socket is not already open or connecting
    if (!ws || ws.readyState === WebSocket.CLOSED || ws.readyState === WebSocket.CLOSING) {
      ws = new WebSocket('ws://localhost:8080'); // Backend WebSocket URL

      ws.onopen = () => {
        console.log('1111111Connected to backend WebSocket');
      };

      ws.onmessage = (event) => {
        let receivedSocketData = JSON.parse(event.data);
        setOrderBookData(receivedSocketData.orderBook || []);
        setAlgoBookData(receivedSocketData.algoBook || []);
        console.log('D1111111ata received from backend:', ws, receivedSocketData.orderBook.length, receivedSocketData.algoBook);
      };

      ws.onclose = () => {
        console.log('We1111111111bSocket connection closed');
      };
    } else if (ws.readyState === WebSocket.OPEN) {
      console.log('WebSocket is already open, no need to reconnect.');
    }
  }

  const closeWebSocket = () => {
    if (ws && (ws.readyState === WebSocket.OPEN || ws.readyState === WebSocket.CONNECTING)) {
      console.log('Closing WebSocket connection...');
      ws.close();  // Close the connection
    } else {
      console.log('WebSocket is not open or already closed.');
    }
  }


  useEffect(() => {
    async function fetchData() {
      // You can await here
      let response = await handleAPIData('/api/contracts', { broker: 'aliceBlue' });
      console.log('########response#useeffect', response);
      // if (response.status === 'success') {
      //   let response = await handleAPIData('/api/symbols', { broker : 'aliceBlue', exchange : 'BFO'.toLowerCase() });
      //   console.log('exchangesymbols', response);
      // }
    }
    fetchData();

    // if (apiToggleChecked) {
    //   setSessionId('ssssss')
    // } else (
    //   setSessionId('')
    // )

  }, []);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const exchangeOptions = [
    {
      id: 'empty',
      value: '',
      label: 'Exchange'
    },
    {
      id: 'EQ',
      value: 'NSE',
      label: 'NSE-Equity'
    },
    {
      id: 'FUTIDX',
      value: 'NFO',
      label: 'NSE-Index-Futures'
    },
    {
      id: 'OPTIDX',
      value: 'NFO',
      label: 'NSE-Index-Options'
    },
    {
      id: 'FUTSTK',
      value: 'NFO',
      label: 'NSE-Stock-Futures'
    },
    {
      id: 'OPTSTK',
      value: 'NFO',
      label: 'NSE-Stock-Options'
    },
    {
      id: 'E',
      value: 'BSE',
      label: 'BSE-EQ'
    },
    {
      id: 'IF',
      value: 'BFO',
      label: 'BSE-Index-Futures'
    },
    {
      id: 'IO',
      value: 'BFO',
      label: 'BSE-Index-Options'
    },
    {
      id: 'SF',
      value: 'BFO',
      label: 'BSE-Stock-Futures'
    },
    {
      id: 'SO',
      value: 'BFO',
      label: 'BSE-Stock-Options'
    },
    {
      id: 'OPTFUT',
      value: 'MCX',
      label: 'MCX-Options-Futures'
    },
    {
      id: 'FUTCOM',
      value: 'MCX',
      label: 'MCX-Commodities'
    }
  ];

  const getOrderDuration = () => {
    if (orderDuration === 'intraday') {
      return 'MIS';
    } else if (orderDuration === 'delivery') {
      return symbolValue === 'fno' ? 'NRML' : 'CNC';
    }
  }

  const getSelectedExchangeValue = () => {
    const filtered = exchangeOptions.filter(x => x.id === selectedExchange);
    return filtered[0].value || '';
  }

  const createOrderPayload = (splitOrderData) => {
    let payload = [];
    try {
      let payloadTemp = [{
        complexty: orderType,
        discqty: disclosedQty || '0',
        exch: getSelectedExchangeValue(),
        pCode: getOrderDuration(),
        prctyp: placeType,
        price: 'MKT' === placeType || 'SL-M' === placeType ? '00.00' : price,
        trigPrice: 'MKT' === placeType || 'L' === placeType ? '00.00' : triggerPrice,
        qty: qty,
        ret: 'DAY',
        symbol_id: symbolValue, // id comes here
        trading_symbol: symbolObject.id.split('_')[1],
        transtype: buySellToggle ? 'BUY' : 'SELL',
        orderTag: 'SHIVOM ALGO'
      }];
      if (orderType === 'co' || orderType === 'bo') {
        for (let x = 0; x < payload.length; x++) {
          payload[x].stopLoss = stopLossPrice;
          payload[x].target = targetPrice;
          payload[x].trailing_stop_loss = trailingStopLossPrice;
        }
      }
      for (let x = 0; x < splitOrderData.length; x++) {
        payload[x] = payloadTemp[0];
        payload[x].qty = splitOrderData[x];
      }
    } catch (e) {
      payload = [];
    }
    return payload;
  }

  const handleAlgoResetClick = (doNotResetArray = []) => {
    if (!doNotResetArray || !doNotResetArray.includes('exchange')) {
      setSelectedExchange('');
    }
    if (!doNotResetArray || !doNotResetArray.includes('symbols')) {
      // setSymbolArray([]);
      setSymbolValue('');
      setSymbolObject(null);
    }
    setOrderType('regular');
    setOrderDuration('intraday');
    setBuySellToggle(false);
    setPlaceType('MKT');
    setQty('');
    setPrice('');
    setTriggerPrice('');
    setDisclosedQty('');
    setDayioc('');
    setSplitOrder('auto');
    setStopLossPrice('');
    setTargetPrice('');
    setTrailingStopLossPrice('');
    setPipsBy('');
    setChannelTrades('');
  }

  const splitOrderConcept = () => {
    const exchangeNature = getSelectedExchangeValue();
    if (!exchangeNature || exchangeNature === '' || exchangeNature === 'empty' || !symbolObject.symbol) {
      toast.error('Please select exchange and symbol', toastOptions);
      return;
    }
    const maxQtyLimit = maxQty(exchangeNature, symbolObject.symbol);
    if (splitOrder === 'auto') {
      if (qty > maxQtyLimit) {
        let times = Math.floor(qty / maxQtyLimit);
        let remQty = qty - (maxQtyLimit * times);
        let arr = [];
        for (let x = 0; x < times; x++) {
          arr.push(maxQtyLimit);
        }
        arr.push(remQty)
        return arr;
      } else {
        return [qty];
      }
    } else if (splitOrder === 'splitQty') {
      if (!splitQtyValue || splitQtyValue === '') {
        toast.error('Please enter split quantity', toastOptions);
        return;
      }
      let times = Math.floor(qty / splitQtyValue);
      let remQty = qty - (splitQtyValue * times);
      let arr = [];
      for (let x = 0; x < times; x++) {
        arr.push(maxQtyLimit);
      }
      arr.push(remQty)
      return arr;
    } else {
      return [qty];
    }
  }

  const callExecutedOrderBook = async () => {
    let resp = await handleAPIData('/api/orderBook', { email: userDetails?.email });
    let response = resp?.data;
    console.log('@#######$$$$$$$$$$$$$$####', response);
    if (brokerType === 'aliceBlue' && response.status === 'success') {
      console.log('@#######$$$$$$$$$$$$$$####', response.data.length);
      setOrderBookData(response.data || []);
    } else {
      setOrderBookData([]);
    }
  }

  const handlePlaceOrderClick = async () => {
    if (!selectedExchange) {
      toast.info('Please select exchange', toastOptions);
      return;
    }
    if (!symbolValue) {
      toast.info('Please select symbol', toastOptions);
      return;
    }
    if (!qty) {
      toast.info('Please enter quantity', toastOptions);
      return;
    }
    const splitOrderData = splitOrderConcept();
    if ((!pipsBy && channelTrades) || (pipsBy && !channelTrades)) {
      toast.error('Please enter pipsBy and channelTrades both', toastOptions);
      return;
    }
    const payload = createOrderPayload(splitOrderData);
    console.log('payloadsplitOrderDatasplitOrderData', payload);
    if (payload && payload.length > 0) {
      setLoading(true);
      let orderRes = await handleAPIData('/api/placeOrder', { email: userDetails?.email, payload, pipsBy, channelTrades, isDoubleCheckbox });
      let orderResponse = orderRes?.data;
      setLoading(false);
      if (brokerType === 'aliceBlue' && orderResponse.status === 'success' && orderResponse?.message && orderResponse?.notLoggedIn) {
        toast.info(orderResponse.message, toastOptions);
      } else if (brokerType === 'aliceBlue' && orderResponse.status === 'success' && orderResponse?.message && orderResponse?.notAliceBlueId) {
        toast.info(orderResponse.message, toastOptions);
      } else if (brokerType === 'aliceBlue' && orderResponse.status === 'success' && orderResponse?.message && orderResponse?.notAliceBlueAPIKey) {
        toast.info(orderResponse.message, toastOptions);
      } else if (brokerType === 'aliceBlue' && orderResponse.status === 'success' && orderResponse?.message && orderResponse?.dupsAlgoTrade) {
        toast.info(orderResponse.message, toastOptions);
      } else if (brokerType === 'aliceBlue' && orderResponse.status === 'error') {
        toast.error('Issue in placeOrder. Pleas try again', toastOptions);
      }
      console.log('loginSetUp', orderResponse.data);
      if (orderResponse?.data && orderResponse.data.length > 0) {
        let ordersCount = 0;
        for (let x = 0; x < orderResponse.data.length; x++) {
          if (orderResponse.data[x].stat === 'Ok' && orderResponse.data[x].NOrdNo) {
            ordersCount++;
          }
        }
        toast.info(`Orders Status. Pass : ${ordersCount} Fail: ${orderResponse.data.length - ordersCount}`, toastOptions);
        handleAlgoResetClick();
        setSymbolArray([]);
        await callExecutedOrderBook();
      }
    } else {
      toast.error('Issue in createOrderPayload()', toastOptions);
    }
  }

  const handleSymbolChange = (selectedOpt) => {
    console.log('id, keyName, placeholder', selectedOpt);
    // dispatch(changeInputFunc({ keyName, value: event.target.value }));
  };

  const handleSymbolOptionClick = (selectedOpt) => {
    console.log('handleSymbolOptionClick', selectedOpt);
    if (selectedOpt && selectedOpt?.value) {
      handleAlgoResetClick(['exchange']);
      setSymbolObject(selectedOpt);
      setSymbolValue(selectedOpt.value);
    } else {
      handleAlgoResetClick(['exchange']);
    }
  }

  const handleSymboleClearableClick = (flag) => {
    return flag ? false : true;
  }

  const handleOrderTypeChange = (event) => {
    console.log('handleOrderTypeChange', event.target.value);
    setOrderType(event.target.value);
    if (event.target.value === 'amo') {
      setPlaceType('L');
    }
  };

  const handleOrderDurationChange = (event) => {
    console.log('handleOrderDurationChange', event.target.value);
    setOrderDuration(event.target.value);
  };

  const handleExchangeChange = async (event) => {
    console.log('handleExchangeChange', event.target.value, event.target.selectedOptions[0].innerText);
    setSelectedExchange(event.target.value);
    setSymbolObject(null);
    setSymbolValue('');
    handleSymboleClearableClick(false);
    handleAlgoResetClick(['exchange']);
    const filtered = exchangeOptions.filter(x => x.id === event.target.value);
    console.log('filtered', filtered);
    if (event.target.value && filtered.length > 0 && filtered[0].value) {
      let resp = await handleAPIData('/api/symbols', { broker: brokerType, exchange: filtered[0].value.toLowerCase() });
      let response = resp.data;
      console.log('exchangesymbols', response);
      if (response.status === 'success' && response.data && response.data.length > 0) {
        let mappedSymbolArray = [];
        toast.info(`Exchange Symbols = ${response.data.length}`, toastOptions);
        if (filtered[0].id === 'EQ') {
          const filteredXX = response.data.filter(o => o.group_name === 'EQ');
          console.log('1', filteredXX.length);
          mappedSymbolArray = filteredXX.map((obj) => ({ lotSize: Number(obj.lot_size), value: obj.token, symbol: obj.symbol, label: obj.symbol, id: `${obj.token}_${obj.trading_symbol}` }));
        } else if (filtered[0].id === 'FUTIDX') {
          const filteredXX = response.data.filter(o => o.instrument_type === 'FUTIDX');
          console.log('2', filteredXX.length, event.target.selectedOptions[0].innerText);
          mappedSymbolArray = filteredXX.map((obj) => ({ lotSize: Number(obj.lot_size), value: obj.token, symbol: obj.symbol, label: obj.formatted_ins_name, id: `${obj.token}_${obj.trading_symbol}` }));
        } else if (filtered[0].id === 'OPTIDX') {
          const filteredXX = response.data.filter(o => o.instrument_type === 'OPTIDX');
          console.log('23', filteredXX.length, event.target.selectedOptions[0].innerText);
          mappedSymbolArray = filteredXX.map((obj) => ({ lotSize: Number(obj.lot_size), value: obj.token, symbol: obj.symbol, label: obj.formatted_ins_name, id: `${obj.token}_${obj.trading_symbol}` }));
        } else if (filtered[0].id === 'FUTSTK') {
          const filteredXX = response.data.filter(o => o.instrument_type === 'FUTSTK');
          console.log('24', filteredXX.length);
          mappedSymbolArray = filteredXX.map((obj) => ({ lotSize: Number(obj.lot_size), value: obj.token, symbol: obj.symbol, label: obj.formatted_ins_name, id: `${obj.token}_${obj.trading_symbol}` }));
        } else if (filtered[0].id === 'OPTSTK') {
          const filteredXX = response.data.filter(o => o.instrument_type === 'OPTSTK');
          console.log('245', filteredXX.length);
          mappedSymbolArray = filteredXX.map((obj) => ({ lotSize: Number(obj.lot_size), value: obj.token, symbol: obj.symbol, label: obj.formatted_ins_name, id: `${obj.token}_${obj.trading_symbol}` }));
        } else if (filtered[0].id === 'E') {
          const filteredXX = response.data.filter(o => o.instrument_type === 'E');
          mappedSymbolArray = filteredXX.map((obj) => ({ lotSize: Number(obj.lot_size), value: obj.token, symbol: obj.symbol, label: obj.symbol, id: `${obj.token}_${obj.trading_symbol}` }));
        } else if (filtered[0].id === 'SF') {
          const filteredXX = response.data.filter(o => o.instrument_type === 'SF');
          mappedSymbolArray = filteredXX.map((obj) => ({ lotSize: Number(obj.lot_size), value: obj.token, symbol: obj.symbol, label: obj.formatted_ins_name, id: `${obj.token}_${obj.trading_symbol}` }));
        } else if (filtered[0].id === 'SO') {
          const filteredXX = response.data.filter(o => o.instrument_type === 'SO');
          mappedSymbolArray = filteredXX.map((obj) => ({ lotSize: Number(obj.lot_size), value: obj.token, symbol: obj.symbol, label: obj.formatted_ins_name, id: `${obj.token}_${obj.trading_symbol}` }));
        } else if (filtered[0].id === 'IF') {
          const filteredXX = response.data.filter(o => o.instrument_type === 'IF');
          mappedSymbolArray = filteredXX.map((obj) => ({ lotSize: Number(obj.lot_size), value: obj.token, symbol: obj.symbol, label: obj.formatted_ins_name, id: `${obj.token}_${obj.trading_symbol}` }));
        } else if (filtered[0].id === 'IO') {
          const filteredXX = response.data.filter(o => o.instrument_type === 'IO');
          mappedSymbolArray = filteredXX.map((obj) => ({ lotSize: Number(obj.lot_size), value: obj.token, symbol: obj.symbol, label: obj.formatted_ins_name, id: `${obj.token}_${obj.trading_symbol}` }));
        } else if (filtered[0].id === 'OPTFUT') {
          const filteredXX = response.data.filter(o => o.instrument_type === 'OPTFUT');
          mappedSymbolArray = filteredXX.map((obj) => ({ lotSize: Number(obj.lot_size), value: obj.token, symbol: obj.symbol, label: obj.formatted_ins_name, id: `${obj.token}_${obj.trading_symbol}` }));
        } else if (filtered[0].id === 'FUTCOM') {
          const filteredXX = response.data.filter(o => o.instrument_type === 'FUTCOM');
          mappedSymbolArray = filteredXX.map((obj) => ({ lotSize: Number(obj.lot_size), value: obj.token, symbol: obj.symbol, label: obj.formatted_ins_name, id: `${obj.token}_${obj.trading_symbol}` }));
        }
        setSymbolArray([...mappedSymbolArray] || []);
      }
    } else {
      setSymbolArray([]);
      toast.error('Please select Exchange', toastOptions);
    }
  };


  const handleBuySellToggleChange = (event) => {
    console.log('handleBuySellToggleChange', event.target.checked);
    setBuySellToggle(event.target.checked);
  };

  const handlePlaceTypeChange = (event) => {
    console.log('handlePlaceTypeChange', event.target.value);
    setPlaceType(event.target.value);
  }

  const handleQtyChange = (event) => {
    console.log('handleQtyChange', event.target.value);
    setQty(event.target.value);
  }

  const handlePriceChange = (event) => {
    console.log('handlePriceChange', event.target.value);
    setPrice(event.target.value);
  }

  const handleTriggerPriceChange = (event) => {
    console.log('handleTriggerPriceChange', event.target.value);
    setTriggerPrice(event.target.value);
  }

  const handleDisclosedQtyChange = (event) => {
    console.log('handleDisclosedQtyChange', event.target.value);
    setDisclosedQty(event.target.value);
  }

  const handlePipsByChange = (event) => {
    console.log('handlePipsByChange', event.target.value);
    setPipsBy(event.target.value);
  }

  const handleChannelTradesChange = (event) => {
    console.log('handleChannelTradesChange', event.target.value);
    setChannelTrades(event.target.value);
  }

  const handleTargetPriceChange = (event) => {
    console.log('handleTargetPriceChange', event.target.value);
    setTargetPrice(event.target.value);
  }

  const handleStoplossPriceChange = (event) => {
    console.log('handleStoplossPriceChange', event.target.value);
    setStopLossPrice(event.target.value);
  }

  const handleTrailStoplossPriceChange = (event) => {
    console.log('handleTrailStoplossPriceChange', event.target.value);
    setTrailingStopLossPrice(event.target.value);
  }

  const handleDayIOCChange = (event) => {
    console.log('handleDayIOCChange', event.target.value);
    setDayioc(event.target.value);
  }

  const handleSplitOrderChange = (event) => {
    console.log('handleSplitOrderChange', event.target.value);
    setSplitOrder(event.target.value);
  }

  const handleSplitQtyValueChange = (event) => {
    console.log('handleSplitQtyValueChange', event.target.value);
    setSplitQtyValue(event.target.value);
  }

  const handleBrokerTypeChange = (event) => {
    console.log('handleBrokerTypeChange', event.target.value);
    setBrokerType(event.target.value);
  }

  const handleAPIToggleChange = async (event) => {
    console.log('handleAPIToggleChange', event.target.checked);
    if (event.target.checked === true && brokerType) {
      await authenticateBroker(event.target.checked);
    } else if (event.target.checked === false && brokerType) {
      await deAuthenticateBroker();
    }
  }

  const tabChangeLogin = () => {
    setShowLogin('show active');
    setShowRegister('');
  }

  const tabChangeRegister = () => {
    setShowLogin('');
    setShowRegister('show active');
  }

  const handleRegisterNameChange = (event) => {
    let value = event.target.value;
    setRegisterName(value);
  }

  const handleLoginEmailChange = (event) => {
    let value = event.target.value;
    setLoginEmail(value.trim());
  }

  const handleLoginPasswordChange = (event) => {
    let value = event.target.value;
    setLoginPassword(value);
  }

  const handleRegisterEmailChange = (event) => {
    let value = event.target.value;
    setRegisterEmail(value);
  }

  const handleRegisterPasswordChange = (event) => {
    let value = event.target.value;
    setRegisterPassword(value);
  }

  const handleRegisterRepeatPasswordChange = (event) => {
    let value = event.target.value;
    setRegisterRepeatPassword(value);
  }

  const resetRegisterInputs = () => {
    setRegisterName('');
    setRegisterEmail('');
    setRegisterPassword('');
    setRegisterRepeatPassword('');
  }



  const handleRegister = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log('save registerRepeatPassword', registerName, registerEmail, registerPassword, registerRepeatPassword);

    if (loading) {
      return;
    }

    if (!termsCheckbox) {
      toast.warning('Please accept terms to register', toastOptions);
      return;
    } else if (!registerName) {
      toast.warning('Please enter your name', toastOptions);
      return;
    } else if (!registerEmail) {
      toast.warning('Please enter a valid email', toastOptions);
      return;
    } else if (!registerPassword) {
      toast.warning('Please enter the password', toastOptions);
      return;
    } else if (!registerRepeatPassword) {
      toast.warning('Please re-enter the password', toastOptions);
      return;
    } else if (registerPassword !== registerRepeatPassword) {
      toast.warning('Passwords do not match', toastOptions);
      return;
    }

    if (registerEmail && !emailRegex.test(registerEmail)) {
      toast.warning('Please enter a valid email', toastOptions);
      return;
    }

    const payload = {
      username: registerName.trim(),
      email: registerEmail.trim(),
      password: registerPassword.trim(),
      phoneNumber: '',
      address: '',
      pointsAvailable: '0',
      expiredOn: '---',
      plan: 'No Active Plan',
    }

    setLoading(true);
    dispatch(resetMyAccountReducerFunc());
    let response = await handleAPIData('/api/register', payload);
    console.log('response11111111', response);
    if (response.status === 'success' && response.data && response.data.email) {
      toast.success('You are registered successfully', toastOptions);
      setUserDetails({
        username: response.data.username,
        email: response.data.email
      });
      dispatch(changeInputFunc({ keyName: 'displayName', value: response.data.username }));
      dispatch(changeInputFunc({ keyName: 'displayEmail', value: response.data.email }));
      dispatch(changeInputFunc({ keyName: 'displayPhone', value: response.data.phoneNumber }));
      dispatch(changeInputFunc({ keyName: 'displayAddress', value: response.data.address }));
      dispatch(changeInputFunc({ keyName: 'pointsAvailable', value: response.data.pointsAvailable }));
      dispatch(changeInputFunc({ keyName: 'expiredOn', value: response.data.expiredOn }));
      dispatch(changeInputFunc({ keyName: 'plan', value: response.data.plan }));
      resetRegisterInputs();
      handleHomeClick();
      console.log('response', response.data);
    } else if (response.status === 'success' && response.data && response.data.isAlreadyRegistered) {
      toast.error(response.data.message, toastOptions);
    } else {
      toast.error('Something went wrong. Please try again.', toastOptions);
    }
    setLoading(false);
  };

  const handleMyAccountClick = () => {
    console.log('userDetails', userDetails);
    resetLoginInputs();
    resetRegisterInputs();
    if (userDetails.email) {
      onCloseModal();
      setMyAccountSection(true);
      setAlgoSection(false);
      setHomeSection(false);
    } else {
      setMyAccountSection(false);
      onOpenModal();
    }
  }

  const handleHomeClick = () => {
    setMyAccountSection(false);
    setAlgoSection(false);
    onCloseModal();
    setHomeSection(true);
  }

  const handleLogOut = () => {
    setUserDetails({});
    dispatch(resetMyAccountReducerFunc());
    handleHomeClick();
    toast.success('You have successfully logged out', toastOptions);
    // console.log('$$$$$$$$$', store.getState());
  }

  const resetLoginInputs = () => {
    setLoginEmail('');
    setLoginPassword('');
  }

  const handleLogin = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (loading) {
      return;
    }
    if (!loginEmail) {
      toast.warning('Please enter registered email', toastOptions);
      return;
    } else if (!loginPassword) {
      toast.warning('Please enter the password', toastOptions);
      return;
    }

    if (loginEmail && !emailRegex.test(loginEmail)) {
      toast.warning('Please enter a valid email', toastOptions);
      return;
    }

    const payload = {
      email: loginEmail.trim(),
      password: loginPassword.trim()
    }

    setLoading(true);
    dispatch(resetMyAccountReducerFunc());
    let response = await handleAPIData('/api/login', payload);
    console.log('sdfsdfsafdsfdsfdsfffd', response)
    if (response.status === 'success' && response.data && response.data.email) {
      toast.success(response.data.message, toastOptions);
      setUserDetails({
        username: response.data.username,
        email: response.data.email
      });
      onCloseModal();
      setMyAccountSection(false);
      dispatch(changeInputFunc({ keyName: 'displayName', value: response.data.username }));
      dispatch(changeInputFunc({ keyName: 'displayEmail', value: response.data.email }));
      dispatch(changeInputFunc({ keyName: 'displayPhone', value: response.data.phoneNumber }));
      dispatch(changeInputFunc({ keyName: 'displayAddress', value: response.data.address }));
      dispatch(changeInputFunc({ keyName: 'pointsAvailable', value: response.data.pointsAvailable }));
      dispatch(changeInputFunc({ keyName: 'expiredOn', value: response.data.expiredOn }));
      dispatch(changeInputFunc({ keyName: 'plan', value: response.data.plan }));
      dispatch(changeInputFunc({ keyName: 'aliceBlueId', value: response.data.aliceBlueId }));
      dispatch(changeInputFunc({ keyName: 'aliceBlueAPIKey', value: response.data.aliceBlueAPIKey }));
      resetLoginInputs();
      console.log('response', response.data);
    } else if (response.status === 'success' && response.data && response.data.invalidPassword) {
      toast.error(response.data.message, toastOptions);
    } else if (response.status === 'success' && response.data && response.data.invalidUser) {
      toast.error(response.data.message, toastOptions);
    } else {
      toast.error('Something went wrong. Please try again.', toastOptions);
    }
    setLoading(false);
  };

  const callBackForLogOut = () => {
    handleLogOut();
  }

  const handleAlgoPageClick = () => {
    setHomeSection(false);
    setMyAccountSection(false);
    onCloseModal();
    setAlgoSection(true);
  }

  const angelBrokingApiSetup = () => {
    // setApiToggle(true);
    // setBrokerType('aliceBlue');
  }


  const authenticateBroker = async (isChecked) => {
    setLoading(true);
    let response = await handleAPIData('/api/loginSetUp', { broker: brokerType, email: userDetails?.email });
    connectWebSocket();
    console.log('response', response);
    if (brokerType === 'aliceBlue' && response.status === 'success' && response.data.notLoggedIn) {
      toast.info(response.data.message, toastOptions);
    } else if (brokerType === 'aliceBlue' && response.status === 'success' && !response.data.sessionId) {
      toast.error(response.data.message, toastOptions);
    } else if (brokerType === 'aliceBlue' && response.status === 'success' && response.data.sessionId) {
      console.log('sessionId =sessionId', response.data.sessionId);
      if (response.data.sessionId) {
        // call the api of contracts
        let resCont = await handleAPIData('/api/contracts', { broker: brokerType });
        if (resCont.status === 'success' && resCont.data.message) {
          toast.success(resCont.data.message, toastOptions);
          console.log('@@@@@@#########', resCont.data.message, response.data.sessionId)
          setSessionId(String(response.data.sessionId));
          setApiToggleChecked(isChecked);
          handleAlgoResetClick();
          await callExecutedOrderBook();
          setLoading(false);
        } else {
          toast.error(resCont.data.message, toastOptions);
          setLoading(false);
        }
      } else {
        setSessionId('');
        setApiToggleChecked(false);
      }
    } else if (brokerType === 'angelBroking' && response.status === 'success' && response.data.sessionId) {
      setApiToggleChecked(isChecked);
      // angelBrokingApiSetup();
    } else {
      toast.error(response.data.message, toastOptions);
    }
    setLoading(false);

  }


  const deAuthenticateBroker = async () => {
    setLoading(true);
    let response = await handleAPIData('/api/deleteSession', { broker: brokerType, email: userDetails?.email });
    console.log('@######', response);
    if (brokerType === 'aliceBlue' && response.status === 'success' && (response.data.notLoggedIn || response.data.apiLoggedOut)) {
      toast.success(response.data.message, toastOptions);
      setSessionId('');
      setApiToggleChecked(false);
      // closeWebSocket();
    } else {
      toast.error('Something went wrong. Please try again.', toastOptions);
    }
    setLoading(false);
  }

  // Custom filter to only show options when user types 2 or more characters
  const filterOption = (option, inputValue) => {
    if (inputValue.length < 2) return false; // Only start filtering if user has typed 2+ characters
    // console.log('#$####@@@@@@@', option, inputValue, option.label.toLowerCase().includes(inputValue.toLowerCase()))
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const handleCancelOrder = async () => {
    let resp = await handleAPIData('/api/cancelOrder', { email: userDetails?.email });
    let response = resp?.data;
    console.log('@#######$$$$$$$$$$$$$$####', response);
    if (brokerType === 'aliceBlue' && response.status === 'success') {
      console.log('@#######$$$$$$$$$$$$$$####', response.data.length);
      await callExecutedOrderBook();
    }
  }

  const deleteAlgoOrder = async (order) => {
    setAlgoBookDataLoading(order.symbol_id);
    let resp = await handleAPIData('/api/deleteAlgoOrder', { token: order?.symbol_id, email: userDetails?.email });
    let orderResponse = resp?.data;
    if (brokerType === 'aliceBlue' && orderResponse.status === 'success' && !orderResponse?.data && orderResponse?.message) {
      toast.info(orderResponse.message, toastOptions);
    } else if (brokerType === 'aliceBlue' && orderResponse.status === 'success' && orderResponse?.data && orderResponse?.message) {
      toast.success(orderResponse.message, toastOptions); 
      console.log('**(****', orderResponse.data); 
      setAlgoBookData(orderResponse.data || []);
      setAlgoBookDataLoading(null);
    } else if (brokerType === 'aliceBlue' && orderResponse.status === 'error') {
      toast.error('Issue in exit Algo Order. Pleas try again', toastOptions);
    }
    console.log('@#######$$$$$$$$$$$$$$####', orderResponse);
    setAlgoBookDataLoading(null);
  }

  console.log('App', loginEmail, 'ddddsd', loginPassword, registerEmail, 'sd', registerPassword, 'dddd', registerRepeatPassword);
  console.log('ww', sessionId, 'rrrr', myAccountSection, userDetails.email, !homeSection, !algoSection);
  return (
    <>
      <div>
        <section className="section-wrapper">
          <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
              <a className="navbar-brand" onClick={handleHomeClick}>Shivom Algo</a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                  <li className="nav-item text-center">
                    <span className="nav-menu-icon img-flight"></span> <br />
                    <a className="nav-link" aria-current="page" onClick={handleAlgoPageClick}>Algo</a>
                  </li>
                  <li className="nav-item text-center">
                    <span className="nav-menu-icon img-invest"></span> <br />
                    <a className="nav-link" aria-current="page">Pricing</a>
                  </li>
                  <li className="nav-item text-center">
                    <span className="nav-menu-icon img-holidays"></span> <br />
                    <a className="nav-link" aria-current="page">Terms</a>
                  </li>
                </ul>
                <ul className="navbar-nav mb-2 mb-lg-0 d-flex nav-secondary">
                  <li className="nav-item" onClick={handleMyAccountClick}>
                    <a className="nav-link">My Account</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link">Support</a>
                  </li>
                  {
                    userDetails && userDetails.email && Object.keys(userDetails).length > 0 &&
                    <li className="nav-item" onClick={handleLogOut} >
                      <a className="nav-link">Log Out</a>
                    </li>
                  }

                </ul>
              </div>
            </div>
          </nav>
          {
            homeSection && !myAccountSection && !algoSection &&
            <section>
              <div className="container-xxl py-5 section-block">
                <div className="row">
                  <div className="col-auto me-auto">
                    <h2>This is Home Page. What Shivom Algo do will come</h2>
                  </div>
                </div>
              </div>
            </section>
          }
          {
            userDetails.email && algoSection && !myAccountSection && !homeSection &&
            <div className="accordion" id="algo-accordion">
              <div className="row margin-auto">
                <div className="col-md-12">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="algo-accordion-headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Broker Connection
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne">
                      <div className="accordion-body">
                        <div className="row">
                          <div className="col-6 col-md-4">
                            <div className="form-check">
                              <input type="radio" id="algo-aliceBlue" name="brokerType" className="form-check-input" onChange={handleBrokerTypeChange} value="aliceBlue" checked={"aliceBlue" === brokerType} />
                              <label className="form-check-label mt-2" htmlFor="aliceBlue">
                                Alice Blue
                              </label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4">
                            <div className="form-check">
                              <input disabled type="radio" id="algo-angelBroking" name="brokerType" className="form-check-input" onChange={handleBrokerTypeChange} value="angelBroking" checked={"angelBroking" === brokerType} />
                              <label className="form-check-label mt-2" htmlFor="angelBroking">
                                Angel Broking
                              </label>
                            </div>
                          </div>
                          <div className="col-6 col-md-4 toggle-flex">
                            <label className="form-check-label mt-1">API Login</label>
                            {
                              loading ? <span className="spinner-grow spinner-grow-md" role="status"></span> :
                                <>
                                  <div className="toggle-switch">
                                    <input type="checkbox" id="apiToggle" className="toggle-checkbox" name="apiToggle" checked={apiToggleChecked} onChange={handleAPIToggleChange} />
                                    <label htmlFor="apiToggle" className="toggle-label"></label>
                                  </div>
                                </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="algo-accordion-headingFour">
                      <button className={`accordion-button ${sessionId && algoBookData.length > 0 ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                        Algo Trades
                      </button>
                    </h2>
                    {
                      sessionId && algoBookData.length > 0 &&
                      <div id="collapseFour" className={`accordion-collapse collapse ${sessionId && algoBookData.length > 0 ? 'show' : ''}`}>
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-6 col-md-4">
                              <div className="order-check">
                                <label className="form-check-label">EXCH:SYMBOL</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Last Price</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Last Action</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Line Trades</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Qty</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Pips</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Set</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Channel</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Delete</label>
                              </div>
                            </div>
                            {/* <div className="col-6 col-md-1">
                            <div className="order-check">
                              <label className="form-check-label">Actions</label>
                            </div>
                          </div> */}
                          </div>
                          {
                            algoBookData && algoBookData.length > 0 && algoBookData.map((order) => {
                              return (
                                <div key={order.orderId}>
                                  <div className="hr-line">
                                  </div>
                                  {
                                    algoBookDataLoading === order.exchSym ?
                                      <p className="placeholder-glow">
                                        <span className="placeholder col-12"></span>
                                      </p> :
                                      <div className={`${order.transtype === 'SELL' ? 'row red-txt' : 'row green-txt'}`}>
                                        <div className="col-6 col-md-4">
                                          <div className="">
                                            {order.exchSym}
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-1">
                                          <div className="">
                                            {order.price}
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-1">
                                          <div className="">
                                            {order.typer.toUpperCase()}
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-1">
                                          <div className="">
                                            {order.lineTrades}
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-1">
                                          <div className="">
                                            {order.qty}
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-1">
                                          <div className="">
                                            {order.pipsBy}
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-1">
                                          <div className="">
                                            {order.set}
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-1">
                                          <div className="">
                                            {order.channelTrades}
                                          </div>
                                        </div>
                                        <div className="col-6 col-md-1">
                                          <div className="bi bi-trash" onClick={() => deleteAlgoOrder(order)}>
                                          </div>
                                        </div>
                                      </div>
                                  }
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="algo-accordion-headingThree">
                      <button className={`accordion-button ${sessionId && orderBookData.length > 0 ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                        Executed Orders
                      </button>
                    </h2>
                    {
                      sessionId && orderBookData.length > 0 &&
                      <div id="collapseThree" className={`accordion-collapse collapse ${sessionId && orderBookData.length > 0 ? 'show' : ''}`}>
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Time</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Type</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-4">
                              <div className="order-check">
                                <label className="form-check-label">Instrument</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-2">
                              <div className="order-check">
                                <label className="form-check-label">Product</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Qty</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Price</label>
                              </div>
                            </div>
                            <div className="col-6 col-md-1">
                              <div className="order-check">
                                <label className="form-check-label">Status</label>
                              </div>
                            </div>
                            {/* <div className="col-6 col-md-1">
                            <div className="order-check">
                              <label className="form-check-label">Actions</label>
                            </div>
                          </div> */}
                          </div>
                          {
                            orderBookData && orderBookData.length > 0 && orderBookData.map((order) => {
                              return (
                                <>
                                  <div className="hr-line">
                                  </div>
                                  <div key={order.Nstordno} className="row">
                                    <div className="col-6 col-md-1">
                                      <div className="">
                                        {order.OrderedTime.split(' ')[1]}
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-1">
                                      <div className={`${order.Trantype === 'S' ? 'red-txt' : 'green-txt'}`}>
                                        {order.Trantype === 'S' ? 'SELL' : 'BUY'}
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-4">
                                      <div className="">
                                        {order.Exchange} : {order.Scripname}
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-2">
                                      <div className="">
                                        {order.Pcode}/{order.Prctype}
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-1">
                                      <div className="">
                                        {order.Qty}
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-1">
                                      <div className="">
                                        {order.Avgprc}{!Number(order.Trgprc) ? '' : `/${order.Trgprc}`}
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-1">
                                      <div className={`${order.Status === 'rejected' || order.Status === 'cancelled' ? 'red-txt' : 'green-txt'}`}>
                                        {order.Status.toUpperCase()}
                                      </div>
                                    </div>
                                    {/* <div className="col-6 col-md-1">
                                    {
                                      (order.Status === 'open' || order.Status === 'pending') &&
                                      <div className="">
                                        <i className="bi bi-pencil-fill"></i>
                                        <i className="bi bi-trash-fill margin-left-input trash-icons" onClick={() => handleCancelOrder(order)}></i>
                                      </div>
                                    }
                                  </div> */}
                                  </div>

                                </>
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="algo-accordion-headingTwo">
                      <button className={`accordion-button ${sessionId ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Algo Place Order Setup
                      </button>
                    </h2>
                    {
                      sessionId &&
                      <div id="collapseTwo" className={`accordion-collapse collapse ${sessionId ? 'show' : ''}`} aria-labelledby="headingTwo">
                        <div className={`accordion-body ${buySellToggle ? 'light-green-color' : 'light-red-color'}`}>
                          {
                            sessionId ?
                              <>
                                <section>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <select id={"algo-exchange-select"} value={selectedExchange} onChange={handleExchangeChange} className="form-select form-select-lg mb-3">
                                        {
                                          exchangeOptions && exchangeOptions.length > 0 && exchangeOptions.map((option) => {
                                            return (
                                              <option key={option.id} value={option.id}>
                                                {option.label}
                                              </option>
                                            )
                                          })
                                        }
                                      </select>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="form-symbol">
                                        <Select
                                          options={symbolArray}
                                          value={symbolObject}
                                          onInputChange={handleSymbolChange}
                                          onChange={handleSymbolOptionClick}
                                          filterOption={filterOption}
                                          placeholder="Symbol Search"
                                          isClearable
                                          isSearchable={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-4 toggle-flex">
                                      <label className="form-check-label mt-1 sell-borser">SELL</label>
                                      <div className="toggle-switch">
                                        <input type="checkbox" id="buySellToggle" className="toggle-checkbox" name="buySellToggle" checked={buySellToggle} onChange={handleBuySellToggleChange} />
                                        <label htmlFor="buySellToggle" className="toggle-label"></label>
                                      </div>
                                      <label className="form-check-label mt-1 buy-borser">BUY</label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-6 col-md-4">
                                      <div className="row">
                                        <div className="form-check margin-left mt-1">
                                          <input type="radio" id="algo-regular" name="orderType" className="form-check-input" onChange={handleOrderTypeChange} value="regular" checked={"regular" === orderType} />
                                          <label className="form-check-label mt-1" htmlFor="regular">
                                            Regular
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="form-check margin-left mt-1">
                                          <input disabled type="radio" id="algo-bo" name="orderType" className="form-check-input" onChange={handleOrderTypeChange} value="bo" checked={"bo" === orderType} />
                                          <label className="form-check-label mt-1" htmlFor="bo">
                                            BO
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="form-check margin-left" mt-1>
                                          <input disabled type="radio" id="algo-co" name="orderType" className="form-check-input" onChange={handleOrderTypeChange} value="co" checked={"co" === orderType} />
                                          <label className="form-check-label mt-1" htmlFor="co">
                                            CO
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-4">
                                      <div className="row">
                                        <div className="form-check margin-left mt-1">
                                          <input type="radio" id="algo-intraday" name="orderDuration" className="form-check-input" onChange={handleOrderDurationChange} value="intraday" checked={"intraday" === orderDuration} />
                                          <label className="form-check-label mt-1" htmlFor="intraday">
                                            Intraday
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="form-check margin-left mt-1">
                                          <input type="radio" id="algo-delivery" name="orderDuration" className="form-check-input" onChange={handleOrderDurationChange} value="delivery" checked={"delivery" === orderDuration} />
                                          <label className="form-check-label mt-1" htmlFor="delivery">
                                            Delivery
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="form-check margin-left mt-1">
                                          <input type="radio" id="algo-amo" name="orderType" className="form-check-input" onChange={handleOrderTypeChange} value="amo" checked={"amo" === orderType} />
                                          <label className="form-check-label mt-1" htmlFor="amo">
                                            AMO
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-4">
                                      <div className="row">
                                        <div className="form-check margin-left mt-1">
                                          <input type="radio" id="algo-limit" name="placeType" className="form-check-input" onChange={handlePlaceTypeChange} value="L" checked={"L" === placeType} />
                                          <label className="form-check-label mt-1" htmlFor="L">
                                            Limit
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="form-check margin-left mt-1">
                                          <input disabled={orderType === 'amo'} type="radio" id="algo-market" name="placeType" className="form-check-input" onChange={handlePlaceTypeChange} value="MKT" checked={"MKT" === placeType} />
                                          <label className="form-check-label mt-1" htmlFor="MKT">
                                            Market
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="form-check margin-left mt-1">
                                          <input disabled={orderType === 'amo'} type="radio" id="algo-sl" name="placeType" className="form-check-input" onChange={handlePlaceTypeChange} value="SL" checked={"SL" === placeType} />
                                          <label className="form-check-label mt-1" htmlFor="SL">
                                            SL-Limit
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="form-check margin-left mt-1">
                                          <input disabled={orderType === 'amo'} type="radio" id="algo-slm" name="placeType" className="form-check-input" onChange={handlePlaceTypeChange} value="SL-M" checked={"SL-M" === placeType} />
                                          <label className="form-check-label mt-1" htmlFor="SL-M">
                                            SL-Market
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row height-btm">
                                    <div className="col-6 col-md-4">
                                      <div className="row">
                                        <div className="margin-left-input mt-2">
                                          <label className="margin-bottom-lot fst-italic" htmlFor="algo-lot-size">Lot Size : <span className="fw-medium">{symbolObject?.lotSize || 0}</span></label>
                                          <input type="text" className="form-control" id="algo-qty" placeholder="Qty" onChange={handleQtyChange} value={qty} />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="margin-left-input mt-2">
                                          <input type="text" className="form-control" id="algo-disclosed-qty" placeholder="Disclosed Qty" onChange={handleDisclosedQtyChange} value={disclosedQty} />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-4 margin-top-input-price">
                                      <div className="row">
                                        <div className="margin-left-input mt-2">
                                          <input type="text" className="form-control" id="algo-price" placeholder="Price" onChange={handlePriceChange} value={price} disabled={"MKT" === placeType || "SL-M" === placeType} />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="margin-left-input mt-2">
                                          <input type="text" className="form-control" id="algo-trigger-price" placeholder="Trigger Price" onChange={handleTriggerPriceChange} value={triggerPrice} disabled={"MKT" === placeType || "L" === placeType} />
                                        </div>
                                      </div>
                                    </div>
                                    {
                                      (orderType === "co" || orderType === "bo") &&
                                      <div className="col-6 col-md-4 margin-top-input-price">
                                        <div className="row">
                                          <div className="margin-left-input mt-2">
                                            <input type="text" className="form-control" id="algo-target" placeholder="Target" onChange={handleTargetPriceChange} value={targetPrice} />
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="margin-left-input mt-2">
                                            <input type="text" className="form-control" id="algo-stoploss" placeholder="Stoploss" onChange={handleStoplossPriceChange} value={stopLossPrice} />
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="margin-left-input mt-2">
                                            <input type="text" className="form-control" id="algo-trailing-stoploss" placeholder="Trail Stoploss" onChange={handleTrailStoplossPriceChange} value={trailingStopLossPrice} />
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  </div>

                                  <div className="row">
                                    {/* <div className="col-6 col-md-3 margin-top-input-day">
                                    <div className="row">
                                      <div className="form-check margin-left mt-1">
                                        <input type="radio" id="algo-day" name="dayioc" className="form-check-input" value="day" onChange={handleDayIOCChange} checked={"day" === dayioc} />
                                        <label className="form-check-label mt-2" htmlFor="day">
                                          Day
                                        </label>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="form-check margin-left mt-1">
                                        <input type="radio" id="algo-ioc" name="dayioc" className="form-check-input" value="ioc" onChange={handleDayIOCChange} checked={"ioc" === dayioc} />
                                        <label className="form-check-label mt-2" htmlFor="limitFor">
                                          IOC
                                        </label>
                                      </div>
                                    </div>
                                  </div> */}
                                    <div className="col-6 col-md-4 margin-top-input-split">
                                      <div className="row">
                                        <div className="form-check margin-left mt-1">
                                          <label className="margin-bottom-lot fst-italic split" htmlFor="algo-lot-size">Split Order</label>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="form-check margin-left mt-1">
                                          <input type="radio" id="algo-auto" name="splitOrder" className="form-check-input" value="auto" onChange={handleSplitOrderChange} checked={"auto" === splitOrder} />
                                          <label className="form-check-label mt-2" htmlFor="auto">
                                            AUTO
                                          </label>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="form-check margin-left mt-1">
                                          <input disabled type="radio" id="algo-split-qty" name="splitOrder" className="form-check-input" value="splitQty" onChange={handleSplitOrderChange} checked={"splitQty" === splitOrder} />
                                          <label className="form-check-label mt-2" htmlFor="splitqty">
                                            QTY
                                          </label>
                                        </div>
                                      </div>
                                      {
                                        splitOrder === "splitQty" &&
                                        <div className="row">
                                          <div className="margin-left-input mt-2">
                                            <input type="text" className="form-control" id="algo-split-qty-value" placeholder="Qty" onChange={handleSplitQtyValueChange} value={splitQtyValue} />
                                          </div>
                                        </div>
                                      }
                                    </div>
                                    <div className="col-6 col-md-4 margin-top-input-split">
                                      <div className="row">
                                        <div className="margin-left mt-2">
                                          <input disabled className="form-check-input" type="checkbox" value="isDoubleCheckbox" id="is-double-check" defaultChecked={isDoubleCheckbox} onChange={() => setIsDoubleCheckbox(!isDoubleCheckbox)} />
                                          <label className="form-check-label mt-2" htmlFor="isDoubleFor">
                                            Is Double
                                          </label>
                                        </div>
                                        <div className="row">
                                          <div className="margin-left-input mt-2">
                                            <input type="text" className="form-control" id="algo-pips-by" placeholder="Pips By" onChange={handlePipsByChange} value={pipsBy} />
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="margin-left-input mt-2">
                                            <input type="text" className="form-control" id="algo-channel-trades" placeholder="Channel Trades" onChange={handleChannelTradesChange} value={channelTrades} />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-4 margin-top-input-day">
                                      <div className="row">
                                        <div className="form-check margin-left bos mt-1">
                                          <button className={`btn btn-primary btn-block ${buySellToggle ? 'green-color' : 'red-color'}`} onClick={handlePlaceOrderClick}>{buySellToggle ? 'BUY' : 'SELL'}</button>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="form-check mt-1">
                                          <button className="btn btn-secondary btn-block reset mx-4" onClick={() => handleAlgoResetClick()}>Reset</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </> :
                              <div className="text-center form-check-label">Please login with API to Broker </div>
                          }
                        </div>
                      </div>
                    }

                  </div>
                </div>
              </div>
            </div>
          }
          {
            !userDetails.email && algoSection && !myAccountSection && !homeSection &&
            <div className="container-xxl py-5 section-block">
              <div className="row">
                <div className="col-auto me-auto">
                  <h2>To access the Algo page please login</h2>
                </div>
              </div>
            </div>
          }
          {
            myAccountSection && userDetails.email && !homeSection && !algoSection &&
            <section>
              <div className="container-xxl section-block-inner">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12 text-center">
                    <h1 className="mb-2">My account</h1>
                    <p className="hero-text">View/manage your account details</p>
                  </div>
                </div>
              </div>
              <div className="container-xxl py-5 section-block">
                <div className="row">
                  <div className="col-3">
                    <div className="row">
                      <DisplayProfile />
                    </div>
                  </div>
                  <div className="col-9">
                    <ul className="nav nav-tabs justify-content-center" id="my-account-details" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="edit-profile" data-bs-toggle="tab" data-bs-target="#my-account-edit-profile" type="button" role="tab" aria-selected="true">Edit profile</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="change-password" data-bs-toggle="tab" data-bs-target="#my-account-change-password" type="button" role="tab" aria-selected="false">Change password</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="subscription" data-bs-toggle="tab" data-bs-target="#my-account-subscription" type="button" role="tab" aria-selected="false">Subscription</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="payment-methods" data-bs-toggle="tab" data-bs-target="#my-account-payment-method" type="button" role="tab" aria-selected="false">Payment method</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="broker-set-up" data-bs-toggle="tab" data-bs-target="#my-account-broker-set-up" type="button" role="tab" aria-selected="false">Broker Set Up</button>
                      </li>
                    </ul>
                    <div className="row tab-margin">
                      <div className="col">
                        <div className="tab-content" id="my-account-content">
                          <EditProfile id={"my-account-edit-profile"} />
                          <ChangePassword id={"my-account-change-password"} callBackForLogOut={callBackForLogOut} />
                          <Subscription id={"my-account-subscription"} />
                          <PaymentMethod id={"my-account-payment-method"} />
                          <BrokerSetUp id={"my-account-broker-set-up"} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          }
        </section>

        <div className="section-bg">
          <div className="container-xxl py-5 section-block">
            <div className="row align-items-center">
              <div className="col">
                <div className="logo mb-2">Shivom Algo</div>
                <p className="text-dark">© 2024 Shivom Algo. All rights reserved.</p>
              </div>
              <div className="col">
                <ul className="list-inline float-end">
                  <li className="list-inline-item"><a>Terms of Use</a></li>
                  <li className="list-inline-item">|</li>
                  <li className="list-inline-item"><a>Privacy and Cookies</a></li>
                  <li className="list-inline-item">|</li>
                  <li className="list-inline-item"><a>Cookie consent</a></li>
                  <li className="list-inline-item">|</li>
                  <li className="list-inline-item"><a>Contact us</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} center>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="my-account-modal-label">{showLogin ? 'Login' : 'Register'}</h1>
            </div>
            <div className="modal-body">
              <ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
                <li className="nav-item" role="presentation">
                  <a className={`nav-link ${showLogin}`} id="tab-login" data-mdb-pill-init role="tab"
                    onClick={tabChangeLogin}>Login</a>
                </li>
                <li className="nav-item" role="presentation">
                  <a className={`nav-link ${showRegister}`} id="tab-register" data-mdb-pill-init role="tab"
                    onClick={tabChangeRegister}>Register</a>
                </li>
              </ul>
              <div className="tab-content">
                <div className={`tab-pane fade login-wrapper ${showLogin}`} id="tab-login" role="tabpanel">

                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="email" id="loginName" className="form-control" onChange={handleLoginEmailChange} placeholder='Email' />
                    {/* <label className="form-label" htmlFor="loginName">Email</label> */}
                  </div>


                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="password" id="loginPassword" className="form-control" onChange={handleLoginPasswordChange} placeholder='Password' />
                    {/* <label className="form-label" htmlFor="loginPassword">Password</label> */}
                  </div>


                  {/* <div className="row mb-4">
                    <div className="col-md-6 d-flex justify-content-center">
                      <a href="#!">Forgot password?</a>
                    </div>
                  </div> */}


                  <button className="btn btn-primary btn-block mb-4" onClick={handleLogin}>Sign In</button>
                  <button className="btn btn-secondary btn-block mb-3 mx-4" onClick={onCloseModal}>Cancel</button>


                  <div className="text-center">
                    <p>Not a member? <a href="#!" onClick={tabChangeRegister}>Register</a></p>
                  </div>
                </div>
                <div className={`tab-pane fade register-wrapper ${showRegister}`} id="tab-register" role="tabpanel">
                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="text" id="register-name" className="form-control" onChange={handleRegisterNameChange} value={registerName} placeholder='Name' />
                    {/* <label className="form-label" htmlFor="register-name">Name</label> */}
                  </div>


                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="text" id="register-email" className="form-control" onChange={handleRegisterEmailChange} value={registerEmail} placeholder='Email' />
                    {/* <label className="form-label" htmlFor="register-email">Email</label> */}
                  </div>


                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="password" id="register-password" className="form-control" onChange={handleRegisterPasswordChange} value={registerPassword} placeholder='Password' />
                    {/* <label className="form-label" htmlFor="register-password">Password</label> */}
                  </div>


                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="text" id="register-repeart-password" className="form-control" onChange={handleRegisterRepeatPasswordChange} value={registerRepeatPassword} placeholder='Repeat Password' />
                    {/* <label className="form-label" htmlFor="register-repeart-password">Repeat password</label> */}
                  </div>

                  <div className="form-check d-flex justify-content-center mb-4">
                    <input className="form-check-input me-2" type="checkbox" value="termsCheckbox" id="register-check" defaultChecked={termsCheckbox} onChange={() => setTermsCheckbox(!termsCheckbox)} />
                    <label className="form-check-label" htmlFor="register-check">
                      I have read and agree to the terms
                    </label>
                  </div>

                  <button className="btn btn-primary btn-block mb-3" onClick={handleRegister}>Sign Up</button>
                  <button className="btn btn-secondary btn-block mb-3 mx-4" onClick={onCloseModal}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </>
  )
};

export default App;
