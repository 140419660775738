// import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

export const handleAPIData = (url, payload) => {
  const apiUrl = `${baseUrl}${url}`;
  console.log('payload', apiUrl, payload)
  return axios.post(apiUrl, payload)
    .then(res => {
      const response = res.data;
      console.log('api response', response)
      if (response.status === 'success' && response) {
        console.log('%%%%%%%%%%%%%%%%')
        return {
          data: response,          
          status: 'success',
        }
      } else if (response.status === 'error') {
        return {
          data: false,  
          message: response.message || '',        
          status: 'error',
        }
      } else {
        const msg = `Error : ${url}`;
        return {
          data: false, 
          status: 'error',
          message: msg
        }
      }
    })
    .catch(err => {
      const msg = `Error : ${url}`;
      return {
        data: false, 
        status: 'error',
        message: msg
      }
    });
}