import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { toastOptions } from '../toastify';
import { handleAPIData } from '../hooks/useCustomApi';
import { changeInputFunc } from '../reducers/myAccountSlice';
import Input from './Input';
import Button from './Button';

const BrokerSetUp = ({ id }) => {
  const dispatch = useDispatch();
  const childRefs = [useRef()];
  const { displayEmail, aliceBlueId, aliceBlueAPIKey } = useSelector(state => {
    console.log('state.myAccount', state)
    return state.myAccount 
  });
  const [loading, setLoading] = useState(false);

  const handleBrokerSetUpClick = async () => {

    if (loading) {
      return;
    }

    if (!aliceBlueId) {
      toast.warning('Please enter AliceBlue Id', toastOptions);
      return;
    }

    if (!aliceBlueAPIKey) {
      toast.warning('Please enter AliceBlue API Key', toastOptions);
      return;
    }

    console.log('aliceBlueI', aliceBlueId, aliceBlueAPIKey);
    // return;

    const payload = {
      type: 'BROKER_ALICEBLUE',
      currEmail: displayEmail,
      aliceBlueId: aliceBlueId.trim(),
      aliceBlueAPIKey: aliceBlueAPIKey.trim()
    }

    setLoading(true);
    let response = await handleAPIData('/api/myAccount', payload);
    console.log('response', response);
    if (response.status === 'success' && (response.data.aliceBlueId || response.data.aliceBlueAPIKey)) {
      toast.success('Broker setup updated successfully', toastOptions);
      handleCancelClick();
      dispatch(changeInputFunc({ keyName: 'aliceBlueId', value: response.data.aliceBlueId }));
      dispatch(changeInputFunc({ keyName: 'aliceBlueAPIKey', value: response.data.aliceBlueAPIKey }));
    } else {
      toast.error('Something went wrong. Please try again.', toastOptions);
    }
    setLoading(false);
  };

  const handleCancelClick = () => {
    for (let index = 0; index < childRefs.length; index++) {
      if (childRefs[index].current) {
        childRefs[index].current.resetRefCalled(index);        
      }
    }
  };

  return (
    <div className="tab-pane fade" id={id} role="tabpanel" tabIndex="0">
      <div className="row">
        <div className="row">
          <div className="col-4 mb-3">
            <Input ref={childRefs[0]} id={"my-account-aliceBlue-id"} keyName={"aliceBlueId"} placeholder={"Enter AliceBlue Id"} />
            <p>{aliceBlueId}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-4 mb-3">
            <Input ref={childRefs[0]} id={"my-account-aliceBlueAPIKey-id"} classes={"broker-api-key-input"} keyName={"aliceBlueAPIKey"} placeholder={"Enter AliceBlue API Key"} />
            <p>{aliceBlueAPIKey}</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Button id={"my-account-broker-set-up-save-btn"} loading={loading} handleBtnClick={handleBrokerSetUpClick} btnType={"primary"} classes={"float-end"} label={"Save"} />
            <Button id={"my-account-broker-set-up-cancel-btn"} handleBtnClick={handleCancelClick} btnType={"secondary"} classes={"float-end mx-4"} label={"Cancel"} />
          </div>
        </div>
      </div>
    </div>
  )
};

export default BrokerSetUp;
